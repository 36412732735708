import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IntlService } from '@rcg/intl';

export type Formatter = (
  field: {
    field: string;
  } & Record<string, unknown>,
  data: Record<string, unknown>,
) => string;

@Injectable({
  providedIn: 'root',
})
export class GridFormatters {
  private intlService = inject(IntlService);
  private locale = toSignal(this.intlService.locale$);

  createFormatters: { [key: string]: Formatter } = {
    currency: (field, data) => this.currencyFormatter(data[field.field]),
    json: (field, data) => this.jsonFormatter(data[field.field]),
    debug: (field, data) => this.debugFormatter(field, data),
  };

  private currencyFormatter = (value: unknown): string => {
    if (typeof value !== 'number') return '';
    try {
      const currentLocale = this.getLocale();
      const formatter = new Intl.NumberFormat(currentLocale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedValue = formatter.format(value ?? 0);
      return formattedValue;
    } catch (error) {
      console.error('Error: FormattersFactory formatting currency', error);
      return '';
    }
  };

  private jsonFormatter(value: unknown): string {
    if (value === undefined || value === null) return '';
    return JSON.stringify(value);
  }

  private debugFormatter(field: Record<string, unknown>, data: Record<string, unknown>): string {
    return JSON.stringify({ field, data });
  }

  private getLocale(): string {
    const locale = this.locale();
    const defaultLocale = 'sl-SI';

    if (!locale) {
      return defaultLocale;
    }
    if (locale.includes('_')) {
      return locale.replace('_', '-');
    }
    return locale;
  }
}
