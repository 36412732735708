import { inject, Injectable } from '@angular/core';
import { Attachment, AttachmentsService } from '@rcg/core';
import { FormDialogService } from '@rcg/forms';
import { tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as dot from 'dot-object';
import { firstValueFrom } from 'rxjs';
import { AttachemntsViewerAction, DownloadAction, GridAction } from '../models/actions';

@Injectable({
  providedIn: 'root',
})
export class GridActionsService {
  private attachmentsService = inject(AttachmentsService);
  private formsDialogService = inject(FormDialogService);
  private messageService = inject(MessageService);

  async initializeActions(actions: GridAction[] | undefined): Promise<GridAction[]> {
    if (!(actions && Array.isArray(actions) && actions.length > 0)) {
      return [];
    }

    const filteredActions = actions.filter((a) => !!a.key && !!a.type);
    if (filteredActions.length === 0) {
      return [];
    }

    return Promise.all(
      filteredActions.map(async (action) => {
        const [text, tooltip] = await Promise.all([
          action.text ? firstValueFrom(tr(action.text)) : Promise.resolve(''),
          action.tooltip ? firstValueFrom(tr(action.tooltip)) : Promise.resolve(''),
        ]);

        return { ...action, text, tooltip };
      }),
    );
  }

  async executeAction(action: GridAction, grid: GridComponent | undefined, selectedRecordData?: Record<string, unknown>): Promise<void> {
    if (!action?.key) throw new Error('No action key provided');
    if (!action?.type) throw new Error('No action type provided');
    if (!grid) throw new Error('No grid refrence provided');

    const rowData = this.getSelectedRecord(grid) ?? selectedRecordData;

    switch (action.type) {
      case 'downloadFile':
        await this.downloadFile(action as DownloadAction, rowData);
        break;
      case 'attachmenstViewer':
        await this.openAttachmentsViewer(action as AttachemntsViewerAction, grid, rowData);
        break;
      default:
        throw new Error(`Unknown action:  ${action?.key} - ${action?.type}`);
    }
  }

  private async downloadFile(action: DownloadAction, rowData: Record<string, unknown> | undefined): Promise<void> {
    if (!rowData) {
      await this.noRowSelectedMessage();
      return;
    }

    const attachmentId = this.getValueByRecordPath(action.config.attachmentIdPath, rowData) as number | undefined;
    if (!attachmentId) throw new Error('Attachment ID not found');

    const blob = await this.attachmentsService.getAttachmentBlobById(attachmentId, false);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = (this.getValueByRecordPath(action.config.fileNamePath, rowData) as string | undefined) ?? 'attachment';
    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  }

  private async openAttachmentsViewer(
    action: AttachemntsViewerAction,
    grid: GridComponent,
    rowData?: Record<string, unknown>,
  ): Promise<void> {
    if (!action.config) throw new Error('No action config provided for attachments viewer');

    const openViewer = (attachments: Attachment[], selected: Attachment) => {
      this.formsDialogService.openAttachmentsViewer({
        attachments: attachments,
        selectedAttachment: selected,
        canDelete: false,
      });
    };

    const createAttachment = (action: GridAction, rowData: Record<string, unknown>) => {
      const attachmentId = this.getValueByRecordPath(action.config?.['attachmentIdPath'] as string | undefined, rowData) as
        | number
        | undefined;
      if (!attachmentId) return null;
      const attachment: Attachment = {
        id: attachmentId,
        file_name:
          (this.getValueByRecordPath(action.config?.['fileNamePath'] as string | undefined, rowData) as string | undefined) ??
          `attachment-${attachmentId}`,
        content_type:
          (this.getValueByRecordPath(action.config?.['contentPath'] as string | undefined, rowData) as string | undefined) ??
          'application/octet-stream',
      };
      return attachment;
    };

    const handleSingleAttachment = async (action: GridAction, rowData?: Record<string, unknown>) => {
      if (!rowData) {
        await this.noRowSelectedMessage();
        return;
      }
      const attachment = createAttachment(action, rowData);
      if (!attachment) throw new Error('Attachment not found');
      openViewer([attachment], attachment);
    };

    const handleCurrentViewAttachments = async (action: GridAction, grid: GridComponent, rowData?: Record<string, unknown>) => {
      const attachments =
        ((grid.getCurrentViewRecords() as Record<string, unknown>[] | undefined)
          ?.map((record) => createAttachment(action, record))
          .filter((a) => !!a?.id) as Attachment[] | undefined) ?? [];
      if (!attachments || attachments.length === 0) {
        return;
      }
      const selectedAttachment = rowData ? createAttachment(action, rowData) : null;
      openViewer(attachments, selectedAttachment ?? attachments[0]);
    };

    const showType = action.config.attachmentsView ?? 'single';
    switch (showType) {
      case 'single':
        await handleSingleAttachment(action, rowData);
        break;
      case 'currentView':
        await handleCurrentViewAttachments(action, grid, rowData);
        break;
      default:
        throw new Error(`Unknown attachments view type: ${showType}`);
    }
  }

  private getValueByRecordPath(path: string | undefined, record: Record<string, unknown>): unknown {
    if (!path) return undefined;
    return dot.pick(path, record);
  }

  private getSelectedRecord(grid: GridComponent | undefined): Record<string, unknown> | null {
    const selectedRecords = grid?.getSelectedRecords();

    if (!selectedRecords || selectedRecords.length === 0) {
      return null;
    }
    return selectedRecords[0] as Record<string, unknown>;
  }

  private async noRowSelectedMessage(): Promise<void> {
    await this.messageService.showWarningSnackbar(await firstValueFrom(tr('no_record_selected_for_action')));
  }
}
